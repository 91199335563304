const headers = [
  {
    text: 'Nombre',
    value: 'conferenceName',
    sortable: false
  },
  {
    text: 'Conferencista',
    value: 'speaker',
    sortable: false
  },
  {
    text: 'Salon',
    value: 'conference_schedule_time.conference_schedule.conference_room.name',
    sortable: false,
    width: '150px'
  },
  {
    text: 'Fecha',
    value: 'conference_schedule_time.conference_schedule.availableDate',
    sortable: false
  },
  {
    text: 'Hora',
    value: 'conference_schedule_time.description',
    sortable: false
  },
  {
    text: 'Estado',
    value: 'status',
    sortable: false
  },
  {
    text: '',
    value: 'actions',
    sortable: false,
    width: '200px'
  }
]
export default headers
