<template>
  <div class="no-gutter justify-center">
    <div class="text-center mt-12">
      <p>
        <span style="font-size: 20px;">
          <strong> Reserva pendiente. </strong>
        </span>
        <br/>
        Es necesario finalizar la reserva para poder solicitar espacios de charlas técnicas
        <br/>
      </p>
      <a
        href="/"
        class="mt-4 elevation-0 text-none mr-2 v-btn v-btn--contained theme--light v-size--default primary"
      >Continuar con la reserva</a>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PendingBooking',
  data: function () {
    return {
      iconOk: require(`@/assets/images/${process.env.VUE_APP_FAIR_RESOURCES_DIRECTORY}/icon-ok.jpg`)
    }
  }
}
</script>
